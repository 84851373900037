<!-- pageName -->
<template>
  <div class="portal_index">
    <component v-bind:is="currentFrame"></component>
  </div>
</template>

<script>
// import ErshiFrame from '../ershi/components/ershiFrame.vue'
const YZSYFrameIndex = () => import('../../yzsy/index.vue')

export default {
  components: {
    YZSYFrameIndex,
  },
  props: {},
  data() {
    return {
      pcmodel: '',
      currentFrame: '',
    }
  },
  computed: {},
  watch: {},
  methods: {
  },
  created() {
    this.pcmodel = JSON.parse(localStorage.getItem('pcmodel'))
    this.currentFrame = 'YZSYFrameIndex'
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */

</style>
